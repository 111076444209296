import mixpanel from "mixpanel-browser";
import { Callback } from "mixpanel-browser";
import { createContext, useCallback, useContext, useRef } from "react";
interface AnalyticsContextType {
  track: (event: string, properties?: Record<string, unknown>, sendImmediately?: boolean, callback?: Callback) => void;
  identify: (userId: string) => void;
  setPeopleProperties: (properties: Record<string, unknown>) => void;
  reset: () => void;
}
const AnalyticsContext = createContext<AnalyticsContextType | null>(null);
export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider");
  }
  return context;
};
interface AnalyticsProviderProps {
  children: React.ReactNode;
  projectToken: string;
  mixpanelHost: string;
  debug: boolean;
}
export const AnalyticsProvider = ({
  children,
  projectToken,
  mixpanelHost,
  debug
}: AnalyticsProviderProps) => {
  const initRef = useRef(false);

  // Initialize during first render if not already done.
  // It's possible that Mixpanel doesn't get loaded due to ad-blockers,
  // so we check here to prevent runtime errors.
  if (!initRef.current && mixpanel) {
    // If not already initialized
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!(mixpanel as any).config) {
      mixpanel.init(projectToken, {
        debug: debug,
        api_host: mixpanelHost,
        track_pageview: false,
        persistence: "localStorage",
        secure_cookie: true
      });
    }
    initRef.current = true;
  }
  const track = useCallback((event: string, properties: Record<string, unknown> = {}, sendImmediately: boolean = false, callback?: Callback) => {
    mixpanel.track(event, properties, {
      send_immediately: sendImmediately
    }, callback);
  }, [mixpanel]);
  const identify = useCallback((userId: string) => {
    mixpanel.identify(userId);
  }, [mixpanel]);
  const setPeopleProperties = useCallback((properties: Record<string, unknown>) => {
    mixpanel.people.set(properties);
  }, [mixpanel]);
  const reset = useCallback(() => {
    mixpanel.reset();
  }, [mixpanel]);
  return <AnalyticsContext.Provider value={{
    track,
    identify,
    setPeopleProperties,
    reset
  }} data-sentry-element="unknown" data-sentry-component="AnalyticsProvider" data-sentry-source-file="AnalyticsProvider.tsx">
      {children}
    </AnalyticsContext.Provider>;
};